@use '@angular/material' as mat;
//@import "~@angular/material/theming";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
//@include mat.all-component-typographies();
@include mat.core();

/*https://stackoverflow.com/questions/58364004/decrease-height-of-mat-button-toggle-to-match-buttons
Making everything consistent
*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-label-content, .mat-mdc-raised-button {
	//line-height: 32px !important;
}

mat-icon {
	//vertical-align: bottom;
}

/*

@function mat-theme-bg-fg($primary, $accent, $warn: mat.define-palette(mat.$red-palette), $foreground: mat.define-palette(mat.$light-theme-foreground-palette), $background: mat.define-palette(mat.$light-theme-background-palette)) {
	@return (
		primary: $primary,
		accent: $accent,
		warn: $warn,
		is-dark: false,
		foreground: $foreground,
		background: $background,
	);
}


$debatt-support-background: (
	//status-bar: green,
	//app-bar: map_get($mat-blue, 900),
	background: orange,
	//hover: rgba(white, 0.04),
	//card: map-get($mat-green, 500),
	card: mat.get-color-from-palette(mat.$green-palette, 300),
	//dialog: map_get($mat-grey, 800),
	//disabled-button: $white-12-opacity,
	//raised-button: map-get($mat-grey, 800),
	//focused-button: $white-6-opacity,
	//selected-button: map_get($mat-grey, 900),
	//selected-disabled-button: map_get($mat-grey, 800),
	//disabled-button-toggle: black,
	//unselected-chip: map_get($mat-grey, 700),
	//disabled-list-option: black,
);


$my-dark-theme-foreground: (
	base: yellow,
	//divider: mat.$white-12-opacity,
	//dividers:mat.$white-12-opacity,
	disabled: rgba(white, 0.3),
	disabled-button: rgba(white, 0.3),
	disabled-text: rgba(white, 0.3),
	hint-text: rgba(white, 0.3),
	secondary-text: rgba(white, 0.7),
	icon: white,
	icons: white,
	text: yellow,
	slider-min: white,
	slider-off: rgba(white, 0.3),
	slider-off-active: rgba(white, 0.3),
);

$debatt-support-primary: mat.define-palette(mat.$green-palette, 900);
$debatt-support-accent: mat.define-palette(mat.$deep-orange-palette, 900);
$debatt-support-warn: mat.define-palette(mat.$red-palette);
$debatt-support-theme: mat.define-light-theme($debatt-support-primary, $debatt-support-accent, $debatt-support-warn);

// Insert custom background color
// merging custom background into the theme
$background: map_merge(map-get($debatt-support-theme, background), $debatt-support-background);
$debatt-support-theme: map_merge($debatt-support-theme, (background: $background));

.argument-support-theme {
	//background-color: mat-color(map_get($debatt-support-theme, background), background);
	//background-color: mat-color($debatt-support-primary, lighter);
	//border: 2px solid;
	//border-color: mat-color($debatt-support-primary);
	//padding: 20px;
	margin: 1em;
	@include mat.all-component-themes($debatt-support-theme);

}

$debatt-statement-primary: mat.define-palette(mat.$blue-palette, 200);
$debatt-statement-accent: mat.define-palette(mat.$deep-orange-palette, 900);
$debatt-statement-warn: mat.define-palette(mat.$red-palette);
$debatt-statement-theme: mat.define-light-theme($debatt-statement-primary, $debatt-statement-accent, $debatt-statement-warn);

.statement-theme {
	//@include angular-material-theme($debatt-statement-theme)
}
*/


@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.rotating{
	animation: rotation 1.5s infinite linear;
}
